import styled from '@emotion/styled'
import { SideBetDecline } from 'assets/images';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
`;


export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
  min-height: 150px;
  color: #ffffff;
  font-size: 16pt;
  font-family: 'Roboto';
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
  user-select: none;
  padding: 10px 0;
`;

export const CloseButton = styled.div`
  margin-top: 10px;
  display: flex;
  background-image: url(${SideBetDecline});
  background-size: 107px 55px;
  background-position: -13px 3px;
  background-color: transparent;
  width: 80px;
  height: 50px;
  font-size: 10pt;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: "Barlow Semi Condensed Bold";
  font-weight: 700;
  cursor: pointer;
  text-transform: uppercase;
`;